@font-face {
  font-family: 'NewsonGLS';
  src: url(/assets/fonts/NewsonGLS-WEB-Regular.woff2) format('woff2'),
    url(/assets/fonts/NewsonGLS-WEB-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'NewsonGLS';
  src: url(/assets/fonts/NewsonGLS-WEB-Italic.woff2) format('woff2'),
    url(/assets/fonts/NewsonGLS-WEB-Italic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: 'NewsonGLS';
  src: url(/assets/fonts/NewsonGLS-WEB-Bold.woff2) format('woff2'),
    url(/assets/fonts/NewsonGLS-WEB-Bold.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'NewsonGLS';
  src: url(/assets/fonts/NewsonGLS-WEB-Bold.woff2) format('woff2'),
    url(/assets/fonts/NewsonGLS-WEB-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'NewsonGLS';
  src: url(/assets/fonts/NewsonGLS-WEB-BoldItalic.woff2) format('woff2'),
    url(/assets/fonts/NewsonGLS-WEB-BoldItalic.woff) format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: 'NewsonGLS';
  src: url(/assets/fonts/NewsonGLS-WEB-Light.woff2) format('woff2'),
    url(/assets/fonts/NewsonGLS-WEB-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'NewsonGLS';
  src: url(/assets/fonts/NewsonGLS-WEB-LightItalic.woff2) format('woff2'),
    url(/assets/fonts/NewsonGLS-WEB-LightItalic.woff) format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}
