.btn:focus,
.btn:hover,
span.button:focus,
span.button:hover {
  text-decoration: none;
}
.mat-mdc-button:not(:disabled) {
  color: initial;
}

.btn.mat-mdc-button,
.mdc-button {
  font-size: 14px;
  line-height: 18px;
  padding: 8px 20px;
  letter-spacing: normal;
}

.btn-primary,
.btn-primary.mat-mdc-button {
  border: 1px solid $button-primary-border-color;
  background: $button-primary-color;
  color: $button-primary-text-color;
  height: 48px;
  font-weight: 400;

  &:not(:disabled) {
    color: $color-white;
    &:hover,
    &:focus {
      border: 1px solid $button-secondary-border-color;
      background: $button-secondary-border-color;
      color: $color-blue;
    }
  }

  &:disabled {
    background-color: $button-disabled-color;
    color: $button-disabled-text-color;
    border: 1px solid $button-disabled-border-color;
  }
}

.btn-secondary,
.btn-secondary.mat-mdc-button {
  border: 1px solid $button-secondary-border-color;
  background: $button-secondary-color;
  height: 48px;
  font-weight: 400;

  &:hover {
    background: $color-blue;
    color: $color-white;
  }

  &:not(:disabled) {
    color: $button-secondary-text-color;

    &:hover,
    &:focus {
      color: $color-white;
      outline: none;
    }
  }

  &:disabled {
    background-color: $button-disabled-color;
    color: $button-disabled-text-color;
    border: 1px solid $button-disabled-border-color;
  }
}

.btn-vertical-expander,
.btn-horizontal-expander {
  flex: none;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: relative;
  padding: 0;
  .mat-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 4px;
    left: 4px;
    transform-origin: 12px 12px;
    transition: rotate 0.2s ease;
    &.expanded {
      rotate: 180deg;
    }
  }
}

.btn-horizontal-expander {
  cursor: pointer;
}
