// Overwrite Material Design Components

$NewsonGLS: 'NewsonGLS', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: $color-blue !important;
}
.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background,
.mat-mdc-checkbox-indeterminate.mat-accent .mdc-checkbox__background {
  background-color: $color-blue !important;
}

.mdc-checkbox__checkmark {
  color: $color-white !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: $color-blue !important;
}

.mdc-form-field {
  letter-spacing: 0 !important;
}

.mat-vertical-content-container {
  margin-left: 0px !important;
}
.mat-vertical-content {
  padding: 0 !important;
}
.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: $color-blue !important;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

.mat-body,
.mat-body-1,
.mat-typography {
  font-family: $NewsonGLS !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
}

.mat-h5,
.mat-typography h5 {
  font-family: $NewsonGLS !important;
  line-height: 22px !important;
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
}
.mat-h4,
.mat-typography h4 {
  font-family: $NewsonGLS !important;
  line-height: 24px !important;
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
}

.mat-h3,
.mat-typography h3 {
  font-family: $NewsonGLS !important;
  line-height: 31px !important;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  color: $color-blue !important;
  letter-spacing: normal !important;
}

.mat-h2,
.mat-typography h2 {
  font-family: $NewsonGLS !important;
  line-height: 44px !important;
  font-size: 2.5rem !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
}

.mat-h2,
.mat-typography h1 {
  font-family: $NewsonGLS !important;
  font-size: 3.4375rem !important;
  margin-bottom: 0.5rem !important;
  font-weight: 700 !important;
  line-height: 61px !important;
  letter-spacing: normal !important;
}

.mdc-checkbox__ripple,
.mdc-checkbox__background {
  border-radius: 4px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: 0 !important;
  left: 0 !important;
}

.mdc-checkbox {
  padding: 0 !important;
  margin: 0 !important;
}

.mat-checkbox-inner-container {
  margin-top: -6px !important;
}

.mdc-icon-button {
  // width: 40px !important;
  padding: 0 !important;
}

.mat-mdc-button > .mat-icon,
.mat-mdc-mini-fab > .mat-icon {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  margin: 0 !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: inherit !important;
}

th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
  padding-left: 24px !important;
}

th.mat-mdc-header-cell:last-of-type,
td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
  padding-right: 24px !important;
}

mat-tab-group,
mat-option,
.mat-mdc-checkbox,
.mat-mdc-header-cell,
.mat-mdc-cell {
  font-family: $NewsonGLS !important;
  letter-spacing: normal;
}

.mdc-data-table__table {
  min-width: auto !important;
}

.mat-mdc-header-cell {
  font-size: 12px;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 !important;
  letter-spacing: normal !important;
}

.mat-mdc-tab {
  margin-top: 13px !important;
  letter-spacing: normal !important;
}

.mdc-tab__content {
  margin: 0 10px !important;
}

.mat-mdc-paginator,
.mat-mdc-footer-cell,
span,
label,
mat-label {
  font-family: $NewsonGLS;
}

.mat-mdc-paginator {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  letter-spacing: normal;
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-stroked-button,
.mat-mdc-flat-button {
  border-radius: 12px !important;
}

.mdc-snackbar__surface {
  background-color: $color-yellow !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: $color-blue !important;
}

.mat-sort-header-arrow {
  color: $color-white !important;
}

.mat-tooltip {
  font-size: 14px !important;
}

.mat-select-auto-width-panel {
  width: auto !important;
  max-width: initial !important;
}

.mat-mdc-dialog-surface {
  padding: 24px !important;
}

.mat-mdc-select {
  letter-spacing: 0 !important;
}

.mdc-dialog__title {
  padding: 0 !important;

  &::before {
    height: 0 !important;
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.87) !important;
  letter-spacing: normal !important;
  font-family: $NewsonGLS !important;
}

.mat-mdc-dialog-actions {
  padding: 0 !important;

  .mb-40 {
    margin: 0 !important;
  }
}

.mat-mdc-dialog-actions .mat-button-base + .mat-button-base,
.mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
  margin-left: 8px !important;
}

.mat-mdc-input-element {
  width: 100% !important;
  max-width: 100% !important;
}

.mat-mdc-checkbox label {
  margin: 2px 0 0 2px !important;
}

.mat-mdc-checkbox-touch-target,
.mdc-checkbox__native-control {
  width: 18px !important;
  height: 18px !important;
}

.form-row {
  margin: 0 !important;
}

.combo-box .mat-mdc-select-trigger {
  padding: 0 10px !important;
}

.row-center {
  align-items: center;
  margin-bottom: 8px;

  label {
    margin: 0;
  }
}

.mat-accent {
  --mdc-circular-progress-active-indicator-color: #061ab1;
  --mat-full-pseudo-checkbox-selected-icon-color: #061ab1;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #061ab1;
  --mat-stepper-header-selected-state-icon-background-color: #061ab1;
  --mat-stepper-header-edit-state-icon-background-color: #061ab1;
}
.mat-accent.mat-icon {
  --mat-icon-color: #061ab1;
}

.mat-accent.mat-mdc-checkbox {
  --mdc-checkbox-selected-focus-icon-color: #061ab1;
  --mdc-checkbox-selected-hover-icon-color: #061ab1;
  --mdc-checkbox-selected-icon-color: #061ab1;
  --mdc-checkbox-selected-focus-state-layer-color: #061ab1;
  --mdc-checkbox-selected-hover-state-layer-color: #061ab1;
  --mdc-checkbox-unselected-pressed-state-layer-color: #061ab1;
}

input {
  font-family: 'NewsonGLS';
  font-weight: 400;
  font-size: 12px !important;
}
