.spotlight {
  font-size: 14px;
}

.spotlight-footnote {
  font-size: 12px;
}
.spotlight-blue {
  color: $color-blue;
  font-weight: 600;
}

.spotlight-black {
  color: $color-text-black;
  font-weight: 600;
}

.spotlight-gray {
  font-weight: 400;
  color: $color-text-gray;
}

.spotlight-title {
  font-size: 20px;
}

.w280 {
  width: 280px;
}

.fullwidth {
  width: 100%;
}

.spotlight-subtitle {
  font-size: 16px;
}

a {
  text-decoration: underline;
}

a:hover,
a:focus {
  color: $color-blue;
  text-decoration: none;
}
