.custom-bottom-sheet {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
  box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.5);
  transition: none;
}

.custom-backdrop {
  background-color: rgba(0, 0, 0, 0);
}

.mat-bottom-sheet-container {
  background-color: $color-white !important;
}

.grecaptcha-badge {
  visibility: hidden !important;
}
