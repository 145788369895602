// Button colors
$button-primary-color: #061AB1;
$button-primary-text-color: #FFF;
$button-primary-border-color: #061AB1;
$button-secondary-color: #F4F5FD;
$button-secondary-text-color: #061AB1;
$button-secondary-border-color: #EEF0FC;

$button-disabled-color: #F5F5F5;
$button-disabled-text-color: #C2C6CA;
$button-disabled-border-color: #F5F5F5;

// Core Variables - Primary Palette
$color-white: #fff;
$color-black: #000;
$color-blue: #061ab1;
$color-yellow: #ffd100;

// Core Variables - Secondary Palette
$color-blue-dark: #00108c;
$color-soft-blue-sky: #c8e7f2;
$color-soft-pink: #f3c8d4;
$color-soft-green: #c0e2ad;

// Core Variables - Accent Colors
$color-accent-blue-electric: #1601ff;
$color-accent-blue-sky: #00c2f0;
$color-accent-orange-bright: #ff860d;

// Core Variables - Color Variations
$color-transparent: rgba(0, 0, 0, 0);
$color-yellow-dark: #d6af00;
$color-soft-yellow: #fff8d8;
$color-gray: #cccccc;
$color-red: #ff0000;

$color-header: #020c4f;
$color-text-black: #1a1a1a;
$color-text-gray:#6D7881;

// Core Variables - Typography
$lead-line-height: 1.4;
$small-line-height: 1rem;

// Bootstrap Variables Overrides
$white: $color-white;
$black: $color-black;
$blue: $color-blue;
$gray: #8b8c8c;
$gray-100: #f3f3f3;
$gray-200: #aeaeae;
$gray-300: #cccccc;
$gray-400: #cccccc;
$gray-500: #cccccc;
$gray-600: #b7b7b7;
$gray-700: #8b8c8c;
$gray-800: #cccccc;
$gray-900: $black;
$light-gray: $gray-100;
$yellow: $color-yellow;
$pink: $color-soft-pink;
$green: $color-soft-green;
$primary: $yellow;
$secondary: $white;
$success: $color-soft-green;
$info: $color-soft-blue-sky;
$warning: $color-soft-yellow;
$danger: $color-soft-pink;
$enable-shadows: true;
$body-bg: $white;
$body-color: $black;
$link-color: $black;
$link-decoration: underline;
$link-hover-color: $color-blue;
$link-hover-decoration: underline;
$font-family-sans-serif: 'NewsonGLS', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-bold;
$line-height-base: 1.5;
$font-size-base: 1rem; // font base 1rem means default browser, which is 16px
$header-level1-font-size: 0.9rem; // font base 1rem means default browser, which is 16px
$header-level1-line-height: 1;
$rfs-class: 'enable';
$h1-font-size: $font-size-base * 3.4375; // 55px
$h2-font-size: $font-size-base * 2.5; // 40px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: $font-weight-normal;
$small-font-size: $font-size-base * 0.75;
$btn-border-radius: 400px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-box-shadow: none;
$input-btn-focus-box-shadow: none;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1rem;
$input-btn-font-size: 14px;
$input-btn-line-height: 1;
$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $small-font-size;
$input-btn-line-height-sm: 0.8;
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $lead-font-size;
$input-btn-line-height-lg: $lead-line-height;

// cards
$card-border-width: 0;
$card-border-radius: 10px;
$card-border-color: transparent;
$card-height: auto;
$card-color: $color-black;
$card-bg: $color-white;
$card-cap-bg: $card-bg;
$card-cap-color: $card-color;
$card-spacer-x: 20px;
$card-spacer-y: 20px;

// include bootstrap to wrap up variables
// and to start creating ours

$zindex-cookiebar: 1080;

// Theme variables
$bg-colors: (
  'white': $color-white,
  'blue': $color-blue,
  'soft-blue-sky': $color-soft-blue-sky,
  'soft-pink': $color-soft-pink,
  'soft-green': $color-soft-green,
  'gray': $gray-100,
);
$font-colors: (
  'black': $color-black,
  'white': $color-white,
  'gray': $gray,
);
$btn-tertiary-colors: (
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$headlines-line-height: (
  'h1': 61px,
  'h2': 44px,
  'h3': 31px,
  'h4': 24px,
  'h5': 22px,
  'h6': 20px,
);

// 2019 overrides - idea is to slowly convert these variables to the bootstrap ones, when possible
// 2019 overrides - GLS Color System
$black-opaque: rgba(0, 0, 0, 0);

$blue90: #00108c;
$blue85: $blue;
$blue80: $blue;
$blue75: $blue;
$blue70: $blue;
$blue65: $blue;
$blue60: $blue;
$blue55: $blue;
$blue50: $blue;
$blue45: $blue;
$blue40: $blue;
$blue35: $blue;
$blue30: $blue;
$blue20: $blue;
$blue10: $blue;

$anthracite: $black; // replace with better naming variable
$dark-gray1: #999999;
$dark-gray2: #808080;
$dark-gray3: #666666;
$dark-gray4: rgba(0, 0, 0, 0.15);
$dark-gray5: rgba(0, 0, 0, 0.15);
$dark-gray6: rgba(0, 0, 0, 0.15);

$gray-discreet: #828282;

$bright-gray1: #f0f0f0;
$bright-gray2: #f6f6f6;
$bright-gray3: #dedede;
$bright-gray4: #d8d8d8;
$bright-gray5: #b3b3b3;
$bright-gray6: #e7e8ed;
$white-faded: #e9e9e9;
$pastel: #ccc;
$form-datepicker-color: #b9bcca;

$yellow-faded1: #f3c17a;
$yellow-faded2: #f3e97a;
$yellow-faded3: #f3d17a;
$yellow-faded4: #edde34;
$yellow-hover: #f2b700;

$red: #da202a;

$parcel-status-color-gray: #808080;
$parcel-status-color-yellow: #edc200;
$parcel-status-color-orange: #ff860d;
$parcel-status-color-green: #008002;
$parcel-status-color-red: #ff0000;

// 2019 overrides - borders
$border-small-blue: 1px solid $blue;
$border-small-yellow: 1px solid $yellow;
$border-small-yellow-hover: 1px solid $yellow-hover;
$border-small-white: 1px solid $white;
$border-small-red: 1px solid $red;
$border-small-blue10: 1px solid $blue10;
$border-small-blue20: 1px solid $blue20;
$border-small-blue30: 1px solid $blue30;
$border-small-blue35: 1px solid $blue35;
$border-small-blue45: 1px solid $blue45;
$border-small-blue65: 1px solid $blue65;
$border-small-blue80: 1px solid $blue80;
$border-small-blue85: 1px solid $blue85;
$border-small-darkgray1: 1px solid $dark-gray1;
$border-small-brightgray1: 1px solid $bright-gray1;
$border-small-brightgray3: 1px solid $bright-gray3;
$border-medium-brightgray3: 2px solid $bright-gray3;
$border-small-brightgray4: 1px solid $bright-gray4;
$border-small-white-faded: 1px solid $white-faded;
$border-small-gray: 1px solid $gray;
$border-small-transparent: 1px solid transparent;
$border-small-pastel: 1px solid $pastel;
$border-small-black-opaque: 1px solid $black-opaque;

// 2019 overrides - font sizes
$font-size-default: 16px;
$font-size-small: 14px;
$font-size-xsmall: 12px;
$font-size-mini: 2px;
$font-size-h1-default: 52px;
$font-size-h2-default: 42px;
$font-size-h3-default: 32px;
$font-size-h4-default: 24px;
$font-size-h5-default: 20px;
$font-size-h1-widget: 32px;
$font-size-h2-widget: 24px;
$font-size-h3-h4-widget: 20px;
$font-size-button-default: 18px;
$font-size-accordion: 20px;
$font-size-advantage-item-h5: 24px;
$font-size-nav-link-default: 18px;
$font-size-lead-default: 18px;

// 2019 overrides - line heights
$line-height-h1-default: 68px;
$line-height-h2-default: 38px;
$line-height-h3-default: 32px;
$line-height-h4-default: 28px;
$line-height-h5-default: 26px;
$line-height-h1-widget: 32px;
$line-height-h2-widget: 28px;
$line-height-h3-h4-widget: 26px;
$line-height-button-default: 22px;
$line-height-data-table-row: 20px;
$line-height-lead-default: 24px;

// 2019 overrides - navbar effects
$navbar-light-color: $link-color;
$navbar-light-hover-color: darken($link-color, 15%);
$navbar-light-active-color: darken($link-color, 15%);
$navbar-light-disabled-color: darken($link-color, 15%);

// 2019 overrides - border radius
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$card-image-top-border-radius: $card-border-radius $card-border-radius 0 0;
$card-image-left-border-radius: $card-border-radius 0 0 $card-border-radius;
$card-image-right-border-radius: 0 $card-border-radius $card-border-radius 0;
$card-image-border-radius: $card-border-radius;

// we override the sans one...
// $font-family-base: 'TheSansGLS', Arial, Helvetica, sans-serif;

// 2019 overrides - Loader
$ph-bg: rgba(255, 255, 255, 0);
$ph-spacer: 24px;

// 2019 overrides - paddings:
$padding-default-button: 8px 20px;
$padding-card-deck: 25px;
$padding-top-caroussel-control: 19px;
$padding-bottom-widget-form-chapter: 7px;
$padding-widget-introduction: 40px 0;
$padding-widget-card-xs: 1rem;
$padding-form-text-default: 11px 40px 11px 10px;
$padding-card-default: 30px;
$padding-carousel-default: 30px;

// 2019 overrides - margins:
$margin-bottom-card-override-bootstrap: 20px;
$margin-bottom-bf-headline: 18px;
$margin-bottom-bf-p: 16px;
$margin-carousel-control-icon: 11px;
$margin-bottom-widget-form-chapter: 1rem;
$margin-bottom-default: 60px;
$margin-plain-text-float-left: 0 22px 12px 0;

// 2019 overrides - box shadows
$box-shadow-default: 0 5px 10px 0 $dark-gray4;
$box-shadow-default-slim: 0 3px 6px 0 $dark-gray5;
$box-shadow-default-wide: 0 15px 20px 0 $dark-gray6;
$box-shadow-sm: none;

//2019 overrides - tables
$table-first-row-bg-color: $white;
$table-first-row-text-color: $black;

// 2019 overrides - widget-specific stuff
$witt002-default-width-height: 24px;
$wiph003-pw-strength-color-default: $bright-gray5;
$wiph003-pw-strength-color-very-weak: darkred;
$wiph003-pw-strength-color-weak: red;
$wiph003-pw-strength-color-good: yellow;
$wiph003-pw-strength-color-strong: #28a745;
$wiph003-pw-strength-color-very-strong: green;
$wiph003-pw-failure: red;

$header-background: $color-blue;
$header-logo-background: transparent no-repeat center;

$header-mobile-height: 104px;
$header-mobile-padding-y: 0;
$header-mobile-padding-x: 15px;
$header-mobile-burger-width: 24px;
$header-mobile-burger-height: 24px;
$header-mobile-burger-icon-bar-width: 24px;
$header-mobile-burger-icon-bar-height: 3px;
$header-mobile-burger-icon-bar-color: $color-white;
$header-mobile-logo-height: 72px;
$header-mobile-logo-width: 158px;

$header-mobile-meta-navigation-height: 112px;
$header-mobile-meta-navigation-line-height-basis: 32;
$header-mobile-navwrapper-line-height: #{$header-mobile-meta-navigation-line-height-basis}px;
$header-mobile-navwrapper-font-size: 20px;
$header-mobile-meta-navigation-bottom-border-width-basis: 1;
$header-mobile-meta-navigation-bottom-border: #{$header-mobile-meta-navigation-bottom-border-width-basis}px solid
  fade-out($color-black, 0.8);

$header-desktop-height: 86px;
$header-desktop-logo-height: 50px;
$header-desktop-logo-width: 110px;

$footer-desktop-height: 188px;

$input-border-color: $color-black;
$input-color: $black;
$input-focus-border-color: $color-soft-blue-sky;
$input-focus-color: $color-black;
$form-group-margin-bottom: 24px;
$form-check-input-gutter: 0;
$input-height: 64px;
$input-padding-y: 20px;
$input-padding-x: 0;
$input-font-size: 16px;

$list-group-bg: $color-blue;
$list-group-border-color: transparent;
$list-group-border-width: 0;
$list-group-border-radius: 0;

// Validity scss messages
$validity-errors: 'invalid-bad-input', 'invalid-custom-error', 'invalid-pattern-mismatch', 'invalid-range-overflow',
  'invalid-range-underflow', 'invalid-step-mismatch', 'invalid-too-long', 'invalid-too-short', 'invalid-type-mismatch',
  'invalid-value-missing', 'invalid-bad-date', 'invalid-custom-1', 'invalid-custom-2', 'invalid-custom-3',
  'invalid-custom-4', 'invalid-required-or', 'invalid-confirm-password', 'invalid-kvk-value', 'invalid-kvk-format',
  'invalid-from-to-values', 'invalid-incomplete-row', 'invalid-insufficient-complete-rows', 'invalid-lunch-time';


// the other colors : see gls-group-variables

$color-background-gray: #f7f7f7;
$form-control-border-color: #c6c6c6;
$border-color-gray: #eaeaea;
$background-gray: #f7f7f7;
$subtitle-gray: #767676;
$color-green: #039855;
$color-border-white: #f1f3fc
