.marker {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.tt-origin-marker {
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/2021_Icon-van-pos.svg');
}

.tt-destination-marker,
.parcel-shop-origin-marker {
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/2021_Icon-location.svg');
}

.parcel-shop-destination-marker {
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/2021_parcelshoplocator.svg');
}

.apl-destination-marker {
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/2021_parcelshoplocator.svg');
}

.maplibregl-ctrl-bottom-right
{
  display: none !important;
}
