.size-40 {
  width: 40px;
  height: 40px;
}

.size-40-expander {
  .mat-icon {
    top: 8px;
    left: 8px;
  }
}
