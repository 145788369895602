/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: NewsonGLS, 'Helvetica Neue', sans-serif;
}

h1,
h2,
p,
pre {
  margin: 0;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
h1,
h2 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
.shadow-box {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

@import './main.scss';
@import './scss/colors.scss';
@import './scss/global/bottom-sheet.scss';
@import './scss/global/snackbar.scss';
@import './scss/global/maps.scss';

@import './scss/media.scss';
@import './scss/fonts.scss';
@import './scss/overwrite-material-design.scss';
@import './scss/buttons.scss';
@import './scss/forms.scss';
@import './scss/sizes.scss';
@import './scss/spotlight.scss';
