// Create 20px space between form columns
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
  
  h2,
  h3,
  h4,
  h5 {
    &.form-chapter {
      margin-bottom: 40px;
      padding-bottom: 14px;
      border-bottom: $border-small-blue30;
    }
  }
  
  .form-textblock {
    padding: 80px 0 31px;
  
    .form-textblock h4 {
      font-size: 20px;
    }
  }
  
  .form-introduction {
    margin-bottom: 1.5rem;
  
    p {
      margin: 0;
      font-size: 18px;
      line-height: 23px;
    }
  }
  
  .form-introduction-no-text {
    h1 {
      margin-bottom: 0;
    }
  }
  
  // optional labels/additional texts (mandatory notice, etc.)
  .form-optional-label {
    font-size: $font-size-small;
    line-height: 19px;
    color: $dark-gray3;
    display: inline-block;
  }
  
  .form-optional-label-left {
    margin-right: 10px;
  }
  
  .form-optional-label-right {
    margin-left: 10px;
  }
  
  // generic form elements
  .form-control {
    border-radius: 0;
    height: 44px;
  }
  
  // remove bootstrap outline
  .form-control:focus {
    box-shadow: none;
  }
  
  .form-group {
    display: block;
    margin-bottom: 20px;
  
    .feedback {
      font-size: $font-size-small;
      padding-top: 5px;
    }
  
    .form-field-icon-holder {
      position: relative;
      padding-right: 40px;
  
      .icon,
      .tt-trigger,
      &.form-control_icon-datepicker::after {
        // html-icon e.g. image, link etc.
        position: absolute;
        top: 13px;
        right: 0;
        width: 18px;
        height: 18px;
      }
    }
  
    label {
      display: block;
      font-size: $font-size-small;
      color: $anthracite;
      margin-bottom: 3px;
    }
  
    // Text inputs (are marked in bootstrap with "form-control")
    input.form-control,
    textarea.form-control,
    input.tt-input {
      display: block;
      font-size: $font-size-default;
      background: $bright-gray2;
      border: none;
      border-bottom: $border-small-blue;
      padding: 14px 10px 13px 10px;
      color: $anthracite;
    }
  
    input.form-control:hover,
    input.tt-input:hover,
    textarea.form-control:hover {
      background-color: $bright-gray1;
    }
  
    input.form-control:focus,
    textarea.form-control:focus {
      background-color: $bright-gray1;
      border-bottom: $border-small-yellow;
    }
  
    //noinspection CssUnusedSymbol,CssUnusedSymbol
    input.tt-input-open,
    input.tt-input-open:focus {
      background-color: $bright-gray1 !important;
      border-bottom: 0 none !important;
      box-shadow: $box-shadow-default-slim !important;
    }
  
    input.form-control:disabled,
    input.form-control[readonly],
    textarea.form-control:disabled,
    textarea.form-control[readonly] {
      background-color: $bright-gray2;
      border: none;
    }
  
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $dark-gray1 !important;
      opacity: 1; /* Firefox */
    }
  
    .form-control:disabled ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white !important;
      opacity: 1; /* Firefox */
    }
  }
  
  .form-group_with_feedback.is-invalid {
    margin-bottom: 14px;
  }
  
  // error status
  .form-group.is-invalid,
  .form-group-unit.is-invalid {
    input.form-control,
    textarea.form-control {
      border-bottom: $border-small-red;
    }
  
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $red !important;
      opacity: 1; /* Firefox */
    }
  
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $red !important;
    }
  
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $red !important;
    }
  
    .feedback {
      color: $red;
    }
  }
  
  .form-group_with_icon.is-invalid {
    .feedback {
      padding-right: 40px;
    }
  }
  
  // remove error mark inside text inputs
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
  }
  
  // remove bootstrap outline
  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus {
    box-shadow: none;
  }
  
  // override special browser css
  
  // -webkit-extra
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-color: $bright-gray2 !important;
    outline: none !important;
  }
  
  hr {
    background: $blue30;
  }
  
  //
  // special form fields
  //
  
  // header search
  .form-group_search-header {
    input.form-control {
      padding-right: 40px;
    }
  
    input.input_search-header {
      display: none;
      height: 39px;
    }
  }
  
  // Disable input spinners
  input[type='number'] {
    -moz-appearance: textfield;
  
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
  
  .form-button-group {
    .btn {
      display: block;
      width: 100%;
  
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  
  // Disable firefox error outline
  input:invalid {
    box-shadow: none;
  }
  
  @each $error in $validity-errors {
    .feedback.#{$error} {
      display: none;
  
      input.#{$error} ~ &,
      textarea.#{$error} ~ &,
      select.#{$error} ~ &,
      .custom-input.#{$error} ~ &,
      .form-field-icon-holder.#{$error} ~ &,
      .twitter-typeahead.#{$error} ~ & {
        display: block;
      }
    }
  }
  
  .form-group .form-label-multiple {
    display: flex;
    align-items: flex-start;
    font-size: $font-size-small;
  }
  
  // submit button loading throbber
  .loading {
    .btn.btn-primary,
    &.btn {
      position: relative;
      overflow: hidden;
  
      &::before {
        content: ' ';
        position: absolute;
        box-sizing: border-box;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 1;
        width: 500%;
        margin-left: -250%;
        animation: loadingAnimation 0.8s linear infinite;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 46%,
            rgba(255, 255, 255, 0.35) 50%,
            rgba(255, 255, 255, 0) 54%
          )
          50% 50%;
      }
    }
  }
  
  @keyframes loadingAnimation {
    0% {
      transform: translate3d(-30%, 0, 0);
    }
    100% {
      transform: translate3d(30%, 0, 0);
    }
  }
  
  .consent-block {
    margin-bottom: 1rem;
  
    &:last-child {
      margin-bottom: 0;
    }
  
    .form-radio {
      margin-bottom: 0.2rem;
    }
  }
  
  .form-text a[href] {
    text-decoration: underline;
  }
  
  
@each $error in $validity-errors {
    .feedback.#{$error} {
      font-size: $font-size-xsmall;
    }
  }
  
  
  textarea#textarea_input_input {
    height: 90px;
  }
  
  .form-group {
    margin-bottom: 8px;
  
    div.nice-select.form-control,
    input[type='text'],
    textarea.form-control,
    input[type='email'],
    input[type='number'],
    input[type='tel'],
    input[type='radio'],
    input[type='search'],
    input[type='password'] {
      border-radius: 12px;
      border: 1px solid $gray-200;
      background: none;
  
      &:hover {
        border: $border-small-gray;
        background: none;
      }
  
      &:focus {
        border: $border-small-blue;
        background: none;
      }
  
      @each $error in $validity-errors {
        &.#{$error} {
          background: none;
          border: $border-small-red;
        }
      }
  
      &:disabled,
      &.disabled {
        border: 1px solid $gray-100;
        background: $gray-100 !important; // some controls override the background color of inputs (looking at you typeahead...)
      }
    }
  
    label {
      margin-bottom: 0.5rem;
      font-size: 16px;
      line-height: 24px;
    }
  
    &.is-invalid {
      input.form-control {
        border: $border-small-red;
      }
    }
  
    .form-control_icon-info {
      padding-right: 0px;
  
      .tt-trigger {
        background-image: get-icon('information-icon_gray');
        background-size: 30px;
        width: 30px;
        height: 30px;
        transform: translate(-15px, -5px);
  
        &:hover {
          background-image: get-icon('information-icon_blue');
        }
      }
  
      input[type='search'] + .tt-trigger {
        transform: translate(-25px, -5px);
      }
  
      input[type='password'] {
        padding-top: 16px;
      }
  
      input[type='password'] + .tt-trigger {
        transform: translate(-40px, -5px);
      }
    }
  }
  
  .form-group {
    input[type='text'],
    input[type='number'],
    input[type='tel'],
    input[type='search'] {
      padding-bottom: 11px;
    }
  }
  
  .form-control {
    height: 44px;
  }
  
  .form_special_attention_checkbox {
    .frame {
      border-radius: 10px;
      text-align-last: left;
  
      .form-check {
        background-color: $color-soft-blue-sky;
  
        .form-check-label {
          background: none;
          margin-top: 15px;
          margin-left: 5px;
  
          &:before {
            margin-top: 5px;
            margin-left: 10px;
            text-align: right;
          }
        }
  
        &.form-check-right {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
  
        &.form-check-left {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
  
      a {
        color: $blue;
      }
  
      p,
      label {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  